<template>
  <v-container>
    <BaseTitlePage>
      <template v-slot:button>
        <BaseButton
          id="btn-create"
          name="btn-create"
          color="secondary"
          title="Novo chamado"
          @click="create"
        />
      </template>
    </BaseTitlePage>

    <BaseSearchPage title="Consulte os chamados em aberto">
      <template v-slot:form>
        <v-form @submit.prevent="search" ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <v-text-field
                label="Nº chamado"
                id="ticketCode"
                name="ticketCode"
                outlined
                dense
                hide-details
                clearable
                v-model="filter.ticketCode"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <v-autocomplete
                label="Status"
                id="situation"
                name="situation"
                dense
                outlined
                hide-details
                clearable
                :items="situation"
                v-model="filter.situation"
              />
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
              v-permission="{
                roles: ['CC1', 'CC2'],
                operator: '!=='
              }"
            >
              <BaseCustomersAutocomplete
                label="Cliente"
                id="customerId"
                name="customerId"
                hide-details
                clearable
                v-model="filter.customerId"
              />
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="3"
              sm="6"
              v-permission="{
                roles: ['CC1', 'CC2'],
                operator: '!=='
              }"
            >
              <BaseBusinessUnit
                hide-details
                id="businessUnitId"
                name="businessUnitId"
                label="Unidade de negócio"
                v-model="filter.businessUnitId"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <v-text-field
                label="Responsável"
                id="responsibleUserName"
                name="responsibleUserName"
                dense
                outlined
                hide-details
                clearable
                v-model="filter.responsibleUserName"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <BaseSupportCategories
                label="Categoria"
                id="categoryId"
                name="categoryId"
                hide-details
                clearable
                v-model="filter.categoryId"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <BaseButton
                type="submit"
                height="40"
                color="primary"
                title="Filtrar"
                icon="fa-solid fa-magnifying-glass"
                id="btn-search"
                name="btn-search"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de chamados">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.subject`]="{ item }">
            <span :title="item.subject">
              {{ doTruncateText(item.subject, 25) }}
            </span>
          </template>

          <template v-slot:[`item.responsibleUserName`]="{ item }">
            <span :title="item.responsibleUserName">
              {{ doTruncateText(item.responsibleUserName, 20) }}
            </span>
          </template>

          <template v-slot:[`item.customerName`]="{ item }">
            <span :title="item.customerName">
              {{ doTruncateText(item.customerName, 20) }}
            </span>
          </template>

          <template v-slot:[`item.creatorUserName`]="{ item }">
            <span :title="item.creatorUserName">
              {{ doTruncateText(item.creatorUserName, 20) }}
            </span>
          </template>

          <template v-slot:[`item.businessUnitName`]="{ item }">
            <span :title="item.businessUnitName">
              {{ doTruncateText(item.businessUnitName, 20) }}
            </span>
          </template>

          <template v-slot:[`item.situation`]="{ item }">
            <span :class="getColor(item.situation)">{{ item.situation }}</span>
          </template>

          <template v-slot:[`item.openDays`]="{ item }">
            <span :class="getDaysColor(item.openDays)">
              {{ formatDays(item.openDays) }}
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="primary" small @click="edit(item)">
                  fa-regular fa-eye
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>

            <v-tooltip bottom v-if="profile">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="ml-2"
                  color="error"
                  small
                  @click="replaceResponsible(item)"
                  v-if="
                    profile.permission === 'ACI2' && item.situation === 'Novo'
                  "
                >
                  fa-solid fa-right-left
                </v-icon>
              </template>
              <span>Substituir responsável</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <DialogDemanded
      v-if="isDemanded"
      :dialog="isDemanded"
      :ticketId="ticketSelected"
      :modeEdit="false"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { getColor, getDaysColor, doTruncateText } from '@/helpers/utils';
import { formatDays } from '@/helpers/formatting';
import { checkPermission } from '@/helpers/permission';
import { SupportService } from '@/services/api/support';

export default {
  components: {
    DialogDemanded: () =>
      import('@/components/pages/supports/dialogs/dialog-demanded')
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    isDemanded: false,
    ticketSelected: null,
    filter: {},
    options: {},
    situation: [
      'Aguardando operadora',
      'Em andamento',
      'Finalizado',
      'Novo',
      'Resolvido'
    ]
  }),

  computed: {
    ...mapGetters({
      profile: 'authentication/getProfile'
    }),

    headers() {
      let headers = [
        { text: 'Nº do chamado', value: 'ticketCode' },
        { text: 'Assunto', value: 'subject' },
        { text: 'Criado por', value: 'creatorUserName' },
        { text: 'Responsável', value: 'responsibleUserName' },
        { text: 'Cliente', value: 'customerName' },
        { text: 'Unidade de negócio', value: 'businessUnitName' },
        { text: 'Status', value: 'situation' },
        { text: 'Dias em aberto', value: 'openDays' },
        { text: '', value: 'action' }
      ];

      if (checkPermission(['CC1', 'CC2'], '===')) {
        headers = headers.filter(
          header =>
            header.value !== 'businessUnitName' &&
            header.value !== 'customerName'
        );
      }

      return headers;
    }
  },

  created() {
    const { situation } = this.$route.query;
    if (situation) this.filter.situation = situation;
  },

  methods: {
    doTruncateText,
    formatDays,
    getColor,
    getDaysColor,

    create() {
      this.$router.push({ name: 'suportes-new' });
    },

    edit(item) {
      this.$store.commit('support/filteredFiles', null);

      this.$router.push({
        name: 'suportes-edit',
        params: { id: item.id, ticketId: item.ticketCode }
      });
    },

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const supportService = new SupportService();
        const { status, data } = await supportService.getSupport({
          page,
          take: itemsPerPage,
          ...this.filter
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    replaceResponsible(item) {
      this.ticketSelected = item.id;
      this.isDemanded = true;
    },

    closeModal(search) {
      this.isDemanded = false;

      if (search) {
        this.search();
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
