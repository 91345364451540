var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BaseTitlePage',{scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('BaseButton',{attrs:{"id":"btn-create","name":"btn-create","color":"secondary","title":"Novo chamado"},on:{"click":_vm.create}})]},proxy:true}])}),_c('BaseSearchPage',{attrs:{"title":"Consulte os chamados em aberto"},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Nº chamado","id":"ticketCode","name":"ticketCode","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.filter.ticketCode),callback:function ($$v) {_vm.$set(_vm.filter, "ticketCode", $$v)},expression:"filter.ticketCode"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-autocomplete',{attrs:{"label":"Status","id":"situation","name":"situation","dense":"","outlined":"","hide-details":"","clearable":"","items":_vm.situation},model:{value:(_vm.filter.situation),callback:function ($$v) {_vm.$set(_vm.filter, "situation", $$v)},expression:"filter.situation"}})],1),_c('v-col',{directives:[{name:"permission",rawName:"v-permission",value:({
              roles: ['CC1', 'CC2'],
              operator: '!=='
            }),expression:"{\n              roles: ['CC1', 'CC2'],\n              operator: '!=='\n            }"}],attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('BaseCustomersAutocomplete',{attrs:{"label":"Cliente","id":"customerId","name":"customerId","hide-details":"","clearable":""},model:{value:(_vm.filter.customerId),callback:function ($$v) {_vm.$set(_vm.filter, "customerId", $$v)},expression:"filter.customerId"}})],1),_c('v-col',{directives:[{name:"permission",rawName:"v-permission",value:({
              roles: ['CC1', 'CC2'],
              operator: '!=='
            }),expression:"{\n              roles: ['CC1', 'CC2'],\n              operator: '!=='\n            }"}],attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('BaseBusinessUnit',{attrs:{"hide-details":"","id":"businessUnitId","name":"businessUnitId","label":"Unidade de negócio"},model:{value:(_vm.filter.businessUnitId),callback:function ($$v) {_vm.$set(_vm.filter, "businessUnitId", $$v)},expression:"filter.businessUnitId"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Responsável","id":"responsibleUserName","name":"responsibleUserName","dense":"","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.filter.responsibleUserName),callback:function ($$v) {_vm.$set(_vm.filter, "responsibleUserName", $$v)},expression:"filter.responsibleUserName"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('BaseSupportCategories',{attrs:{"label":"Categoria","id":"categoryId","name":"categoryId","hide-details":"","clearable":""},model:{value:(_vm.filter.categoryId),callback:function ($$v) {_vm.$set(_vm.filter, "categoryId", $$v)},expression:"filter.categoryId"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"6"}},[_c('BaseButton',{attrs:{"type":"submit","height":"40","color":"primary","title":"Filtrar","icon":"fa-solid fa-magnifying-glass","id":"btn-search","name":"btn-search"}})],1)],1)],1)]},proxy:true}])}),_c('BaseTableList',{attrs:{"title":"Listagem de chamados"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.subject`,fn:function({ item }){return [_c('span',{attrs:{"title":item.subject}},[_vm._v(" "+_vm._s(_vm.doTruncateText(item.subject, 25))+" ")])]}},{key:`item.responsibleUserName`,fn:function({ item }){return [_c('span',{attrs:{"title":item.responsibleUserName}},[_vm._v(" "+_vm._s(_vm.doTruncateText(item.responsibleUserName, 20))+" ")])]}},{key:`item.customerName`,fn:function({ item }){return [_c('span',{attrs:{"title":item.customerName}},[_vm._v(" "+_vm._s(_vm.doTruncateText(item.customerName, 20))+" ")])]}},{key:`item.creatorUserName`,fn:function({ item }){return [_c('span',{attrs:{"title":item.creatorUserName}},[_vm._v(" "+_vm._s(_vm.doTruncateText(item.creatorUserName, 20))+" ")])]}},{key:`item.businessUnitName`,fn:function({ item }){return [_c('span',{attrs:{"title":item.businessUnitName}},[_vm._v(" "+_vm._s(_vm.doTruncateText(item.businessUnitName, 20))+" ")])]}},{key:`item.situation`,fn:function({ item }){return [_c('span',{class:_vm.getColor(item.situation)},[_vm._v(_vm._s(item.situation))])]}},{key:`item.openDays`,fn:function({ item }){return [_c('span',{class:_vm.getDaysColor(item.openDays)},[_vm._v(" "+_vm._s(_vm.formatDays(item.openDays))+" ")])]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_vm._v(" fa-regular fa-eye ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]),(_vm.profile)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(
                  _vm.profile.permission === 'ACI2' && item.situation === 'Novo'
                )?_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.replaceResponsible(item)}}},on),[_vm._v(" fa-solid fa-right-left ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Substituir responsável")])]):_vm._e()]}}],null,true)})]},proxy:true}])}),(_vm.isDemanded)?_c('DialogDemanded',{attrs:{"dialog":_vm.isDemanded,"ticketId":_vm.ticketSelected,"modeEdit":false},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }