export const formatMoney = value =>
  value
    ? `R$ ${value.toLocaleString('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        currency: 'BRL'
      })}`
    : 'R$ 0,00';

export const formatDate = value => {
  if (!value) return '';

  if (value.length === 10) {
    value = value.replace(/\//g, '-');
    value += 'T00:00:00.000';
  }

  const date = new Date(value);
  return date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
};

export const formatDateTime = value =>
  value
    ? new Date(value).toLocaleDateString('pt-BR', {
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        month: 'numeric',
        second: 'numeric',
        year: 'numeric'
      })
    : null;

export const formatZipCode = value => {
  if (!value || !/^\d{8}$/.test(value)) return value;

  return `${value.slice(0, 5)}-${value.slice(5, 8)}`;
};

export const formatPhone = value => {
  if (!value) return '';

  if (!/^\d+$/.test(value)) {
    throw new Error(`O valor ${value}, não é válido!`);
  }

  const phoneNumber = value.toString();
  const phoneLength = phoneNumber.length;

  if (phoneLength !== 10 && phoneLength !== 11) {
    throw new Error(`O valor ${value}, não é válido!`);
  }

  const phoneRegex =
    phoneLength === 10
      ? /([0-9]{2})([0-9]{4})([0-9]{4})/
      : /([0-9]{2})([0-9]{5})([0-9]{4})/;
  const phoneParts = phoneRegex.exec(phoneNumber);

  return `(${phoneParts[1]}) ${phoneParts[2]}-${phoneParts[3]}`;
};

export const formatCpf = value => {
  if (!value) return '';

  const formattedCpf = value
    .toString()
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

  if (formattedCpf.length !== 14)
    throw new Error(`O valor ${value}, não é válido!`);

  return formattedCpf;
};

export const formatCnpj = value => {
  if (!value) return '';

  if (!/^\d+$/.test(value) || value.toString().length !== 14) {
    throw new Error(`O valor ${value}, não é válido!`);
  }

  return value
    .toString()
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const formatPercentage = value => {
  if (!value) return '';

  if (!isNaN(value)) {
    return `${value.toLocaleString('pt-BR')}%`;
  } else {
    throw new Error(`O valor ${value}, não é válido!`);
  }
};

export const formatDays = value => {
  if (value == null) return null;
  return value === 0 ? 'Hoje' : `${value} dia${value !== 1 ? 's' : ''}`;
};

export const formatBytes = bytes => {
  if (!bytes || bytes === 0) return '0 bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`;
};
